import React, { Component, Fragment } from 'react';
import { Container, Row, Col,CardDeck,Card,CardImg, Media } from 'react-bootstrap';
import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import TopBanner from '../TopBanner/TopBanner';
import TopNavigation from '../TopNavigation/TopNavigation';
import Footers from '../Footer/Footer';
import opd from '../../assets/images/clinics/opd.jpg';
import cataract from '../../assets/images/clinics/cataract.jpg';
import glucoma from '../../assets/images/clinics/glucoma.jpg';
import vretina from '../../assets/images/clinics/vretina.jpg';
import paediatric from '../../assets/images/clinics/paediatric.jpg';
import orbit from '../../assets/images/clinics/orbit.jpg';
import cornea from '../../assets/images/clinics/cornea.jpg';
import lowvision from '../../assets/images/clinics/lowvision.jpg';

const h="230";
const w="420"



class ClinicalActivitiesPage extends Component{
  componentDidMount(){
    window.scroll(0,0)
  }
    render(){     
        return(
            <Fragment>
               <TopNavigation/>
               <TopBanner/>
              <Container className="text-center">
                <div className="clinicalActivitiesTitle text-center">
                  CLINICAL ACTIVITIES
               </div>
                  <Row> 
                    <CardDeck>
                      <Card>
                        <Card.Img variant="top" src={opd} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Out Patient Department</Card.Title>
                          <Card.Text className="cardTextAlignment">
                            Around three hundred patients are receiving treatment and advice everday through out out patient department on first come first serve basis.
                            In addition of providing routine treatment, all relevant ophthalmic investigations and day surgery facilities are also available here. The hospital has also facilities for evening consultation at the OPD department.
                      </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Last updated 3 mins ago</small>
                        </Card.Footer>
                      </Card>
                      <Card>
                        <Card.Img variant="top" src={cataract} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Cataract Service</Card.Title>
                          <Card.Text className="cardTextAlignment">
                            Cataract is the leading cause of blindness in the country. Vision loss due to cataract can be restored througth a simple surgical procedure.
                            Cataract consultation and surgeries are major part of the activies of the hosptial. Phacoemulsification, small incision cataract surgeries and complicated cataracts are being done regularly by a group of well experienced surgeon through day care service.
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Last updated 3 mins ago</small>
                        </Card.Footer>
                      </Card>
                      <Card>
                        <Card.Img variant="top" src={glucoma} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Glaucoma Service</Card.Title>
                          <Card.Text className="cardTextAlignment">
                            Glaucoma is a complex and silent catastrophic eye disease which can be managed by proper treatment. The glaucoma service at Chevron Eye Hospital is managed by a skilled and competent team. The clinic is equipped with the most advanced diagnostic equipments to diagnose glaucoma a the very early stage.
                            The clinic also deals a large number of primary and secondary glaucoma patients referred from different parts of the country.
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Last updated 3 mins ago</small>
                        </Card.Footer>
                      </Card>
                    </CardDeck>
                </Row>
                <Row> 
                    <CardDeck>
                      <Card>
                        <Card.Img variant="top" src={vretina} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Vitreo-Retina Service</Card.Title>
                          <Card.Text className="cardTextAlignment"> 
                            People with retinal diseases are increasing day by day. Some retinal diseases are common and easily manageable, while others are complex and difficult to 
                            diagnose and require complex treatment. In Chattogram Vitreo-Retinal service is not easily available. Considering this fact and to make the service easily available to the retinal patients,
                            the Retina Department has been set up and equipped with modern equipments and facilities. The clinic is efficiently run and managed by skilled team.
                      </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Last updated 3 mins ago</small>
                        </Card.Footer>
                      </Card>
                      <Card>
                        <Card.Img variant="top" src={paediatric} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Paediatric Service</Card.Title>
                          <Card.Text className="cardTextAlignment">
                            The Paediatric Ophthalmology Department of Chevron Eye Hospital and Research Center is unique in providing comprehensive services to all children 
                            aged below 16 years and its well recognized as one of the best Paediatric Ophthalmology service center in Chattoram.
                            The childred eye diseases are being medically treated and operated through this clinic by skilled and dedicated medical and surgical team.
                      </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">Last updated 3 mins ago</small>
                        </Card.Footer>
                      </Card>
                      <Card>
                        <Card.Img variant="top" src={orbit} height={h} width={w}/>
                        <Card.Body>
                          <Card.Title className="cardTitle">Orbit and Oculoplasty Service</Card.Title>
                          <Card.Text className="cardTextAlignment">
                            A large number of patients with a variety of extra ocular disorders are seen every day at the clinic. Disorders related to eyelid, lacrimal system, 
                            orbital and periorbital region are dealt with by an efficient team effort. Related surgeries are perfromed in a dedicated well equipped opreation theatre.
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                          {/* <small className="text-muted">Last updated 3 mins ago</small> */}
                        </Card.Footer>
                      </Card> 
                    </CardDeck>
                </Row>
              </Container>
              <Footers/>
            </Fragment>
        );
        }
      }   
export default ClinicalActivitiesPage;