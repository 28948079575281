import React, { Component, Fragment } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/custom.css';




class TopBanner extends Component{
    render(){
        return(
            <Fragment>
                <Container  fluid={true} className="topFixedBanner p-0 col-12 col-sm-">
                    <div className="topBannerOverlay">
                        <Container className="topBannerH2">
                            <Row >
                                <Col>
                                    <h2 > Welcome to Chevron Eye Hospital and Research Center website. <br/>
                                                    The site is under development.</h2>
                                </Col>
                              
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default  TopBanner;