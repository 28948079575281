import React, { Component } from "react";
import Slider from "react-slick";
import { Card,CardImg, Media } from 'react-bootstrap';


import drShamin from '../../assets/images/doctors/Dr. Golam Mostafa Chowdhury_2.JPG';
import drKarim from '../../assets/images/doctors/Dr. M. A. Karim New Photo.JPG';
import drDelwar from '../../assets/images/doctors/Dr. Md. Delwar Hossain.JPG';
import drShahadat from '../../assets/images/doctors/Dr. Shahadat-1.JPG';
import drShikdar from '../../assets/images/doctors/Dr. Abdul Mannan Sikder.JPG';
import drdewan from '../../assets/images/doctors/Dr. Sumedh Dewan.jpg';
import drMurtuza from '../../assets/images/doctors/Dr. Murtuza Nuruddin.jpg';
import drWahid from '../../assets/images/doctors/Dr. Wahid Alam.jpg';
import drTasmia from '../../assets/images/doctors/Dr. Tasmia Tahmid.jpg';
import avatar from '../../assets/images/doctors/avatar.png';
import drHeba from '../../assets/images/doctors/Dr Heba.JPG';

import TopNavigation from '../TopNavigation/TopNavigation';
import TopBanner from '../TopBanner/TopBanner';
import Footers from '../Footer/Footer';

export default class Doctors extends Component {
  componentDidMount(){
    window.scroll(0,0)
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      autoplay:true,
      autoplayspeed: 2000,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      
      <div>
        <TopNavigation/>
            <TopBanner/>
        <div className="clinicalActivitiesTitle text-center">
                  DOCTORS' INFORMATION
               </div>
        <Slider {...settings}>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drShamin}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr Golam Mostafa Chy. (Shamim)</h5>
                        <p className="product-price-on-card">DCO MBBS</p>
                        <p className="product-price-on-card">Consultant Ophthalmologist and Phaco Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drKarim}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr. M A Karim PhD</h5>
                        <p className="product-price-on-card">FRCS DCO MBBS</p>
                        <p className="product-price-on-card">Glaucoma Fellow <br /> (LVPEI, India and Columbia University, USA)</p>
                        <p className="product-price-on-card">Glaucoma Specialist and Phaco Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>

          <div>
                <Card className="card w-100 image-box ">
                     <img src={drDelwar}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr M Delwar Hossain</h5>
                        <p className="product-price-on-card">MBBS DCO <br />  Fellow Vitreo - Retina (Sankara Nethralaya, India)</p>
                        <p className="product-price-on-card">Ophthalmologist and Vitreo Retinal Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drShahadat}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr Md Shahadat Hossain</h5>
                        <p className="product-price-on-card">DCO MBBS <br /> Fellow Phaco Surgery (India)</p>
                        <p className="product-price-on-card">Consultant Ophthalmologist and Phaco Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drShikdar}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr Abdul Mannan Sikder</h5>
                        <p className="product-price-on-card">DCO MBBS <br /> Ex. Associate Professor, Institute of Community Ophthalmology <br /> Fellow Paediatric Ophthalmology and Strabismus (Pakistan)</p>
                        <p className="product-price-on-card"> Eye Specialist and Phaco Surgeon </p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drdewan}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr Sumedh Dewan</h5>
                        <p className="product-price-on-card">DCO MBBS<br /> Fellow, Cornea and Anterior Segment, LVPEI, India</p>
                        <p className="product-price-on-card">Consultant Ophthalmologist  <br /> Cornea Specialist and Phaco Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drMurtuza}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr. Murtuza Nuruddin</h5>
                        <p className="product-price-on-card">FRCS, FCPS, DCO, MBBS <br />Fellow Oculoplasty (Canada) and Phaco Surgery (India) </p>
                        <p className="product-price-on-card">Consultant Ophthalmologist and Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drWahid}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr. Wahid Alam</h5>
                        <p className="product-price-on-card">DCO MBBS<br /> Fellow Vitreo Retina</p>
                        <p className="product-price-on-card">Consultant Ophthalmologist and Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drTasmia}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr Tasmia Tahmid</h5>
                        <p className="product-price-on-card">FCPS DCO MBBS</p>
                        <p className="product-price-on-card">Consultant Ophthalmologist and Surgeon</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={avatar}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr. Sumaira Huda</h5>
                        <p className="product-price-on-card">DCO MBBS</p>
                        <p className="product-price-on-card">Junior Consultant</p>
                     </Card.Body>
                 </Card>
          </div>
          <div>
                <Card className="card w-100 image-box ">
                     <img src={drHeba}/>
                     <Card.Body>
                        <h5 className="product-name-on-card">Dr. Heba Hoque</h5>
                        <p className="product-price-on-card">MS MBBS</p>
                        <p className="product-price-on-card">Junior Consultant</p>
                     </Card.Body>
                 </Card>
          </div>
        </Slider>
        <Footers/>

      </div>
    );
  }
}