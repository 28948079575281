
import React, { Component, Fragment } from 'react';

import  Home from './components/TopBanner/Home';
import ClinicalActivitiesPage from './components/ClinicalActivities/ClinicalActivitiesPage';
import AppointmentPage from './pages/Appointment/AppointmentPage';
// import Academics from './components/Academics/Academics';
import Academics from './pages/Academics/Admission';
import Career from './pages/Career/CareerPage';
import ContactUs from './components/ContactUs/Contact';
import AboutUs from './components/AboutUs/AboutUs';
import OutreachProgramme from './components/OutreachProgramme/OutreachProgramme';
import Doctors from './components/Doctors/DoctorInformationPage';
// import {Router,Switch, Route} from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


// import ClinicalActivitiesMedia from './components/ClinicalActivities/ClinicalActivitiesPage';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// ---------------------ToMenuNavigation Starts

import Navbar from './components/TopNavigation/Navbar';


// ------------ToMenuNavigation Ends


function App() {
  return (
          <Router>   
                {/* <Navbar /> */}
                  <Switch>
                    <Route path='/' exact component={Home} />
                    <Route path='/about-us' component={AboutUs} />
                    <Route path='/clinical-activities' component={ClinicalActivitiesPage} />
                    <Route path='/outreach-programme' component={OutreachProgramme} />
                    <Route path='/our-doctors' component={Doctors} />
                    <Route path='/academics' component={Academics} />
                    <Route path='/contact-us' component={ContactUs} />
                    <Route path='/appointment' component={AppointmentPage} />
                    <Route path='/career' component={Career} />
                  </Switch>  
          </Router> 
                
   
  );
}

export default App;
