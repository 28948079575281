import React, { Component, Fragment } from 'react';
import circular from '../../assets/files/Program Circular.jpeg';
import circular2 from '../../assets/files/Clinical Optometry.jpg';
import pdf from '../../assets/files/Application form.pdf';
import word from '../../assets/files/Application form.docx';

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';


import TopBanner from '../../components/TopBanner/TopBanner';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footers from '../../components/Footer/Footer';


function Circular() {
  return (
    
    <div className="text-center text-md-center">
        <TopNavigation/>
        <TopBanner/>
      <div className="clinicalActivitiesTitle">
          TRAINING PROGRAMMES
      </div>
      <img src={circular2} alt="logo" />
        <br/>
        <div class="mb-2">
            <Button variant="primary" size="lg"  href={pdf}>Download application form (PDF)</Button>
            <Button variant="success" size="lg"  href={word}>Download application form (word)</Button>
        </div>   
        <br/>
     <img src={circular} alt="logo" />
        <br/>
        <div class="mb-2">
            <Button variant="primary" size="lg"  href={pdf}>Download application form (PDF)</Button>
            <Button variant="success" size="lg"  href={word}>Download application form (word)</Button>
        </div>   
        <Footers/>
  </div>
  );
}

export default Circular;
