import React, { Component, Fragment } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/custom.css';

import TopBanner from '../TopBanner/TopBanner';
import TopNavigation from '../TopNavigation/TopNavigation';
import Footers from '../Footer/Footer';


class AboutUs extends Component{
   componentDidMount() {
    window.scroll(0,0)
    }




    render(){     
        return(
          <Fragment>
            <TopNavigation/>
            <TopBanner/>
            <Container>
                <div>
                    <Container >
                        <Row xs="1" style={{fontFamily: "Arial", fontSize: 20 }}> 
                            <Col>
                            <p style={{ fontSize: 30, fontWeight:"Bold"  }}> About the Organization </p>
                            </Col>
                        </Row>
                        <Row xs="1" >
                            <Col>
                                <p style={{ fontSize: 20, textAlign: "justify" }}> <b>Chattogram City</b> is the second dense populated city in Bangladesh and about 60 lac people reside in Chattogram Metropolitan
area. Every year a large number of population adds with the present population. To meet the growing need of eye care facilities 
of the population, still there is dearth setup of modern equipped eye health care center. With that end in view and to meet the higher
demands to improve the eye care facilities as well as to provide wub-specialized services to the people of this region the Chevron Eye Hospital
and Research Center has been set up at Panchlaish, Chattogram with all modern equipments and instruments including all other facilities. A group of 
renowned Oththalmologist and surgeons of the country with the support of highly trained Mid-Level Eye Care
Personnel's are providing eye treatment and performing necessary surgeries to the patients. With the establishment of <b> Chevron Eye Hospital and 
Research Center</b> not only a large number of eye patients of Chattogram region are receiving improved eye care services but people of the country 
are also beigh benefited and receiving sub-specialized eye cre facilities.</p>
                            </Col>
                           
                          
                        </Row>
                    </Container>
                </div>
            </Container>
            <Footers/>

        </Fragment>


        );
        }
      }   
export default AboutUs;