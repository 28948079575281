import React, { Component, Fragment } from 'react';
import vacancy1 from '../../assets/files/Vacancy.jpg';
import pdf from '../../assets/files/Vacancy.pdf';

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';


import TopBanner from '../../components/TopBanner/TopBanner';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import Footers from '../../components/Footer/Footer';


function Career() {
  return (
    
    <div className="text-center text-md-center">
        <TopNavigation/>
        <TopBanner/>
      <div className="clinicalActivitiesTitle">
          JOB VACANCY
      </div>
      <img src={vacancy1} alt="logo" />
        <br/>
        <div class="mb-2">
            <Button variant="primary" size="lg"  href={pdf}>Download Circular</Button>
        </div>   
        <br/>
     
        <Footers/>
  </div>
  );
}

export default Career;
