import React, { Component, Fragment } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/custom.css';


import TopNavigation from '../../components/TopNavigation/TopNavigation';
import TopBanner from '../../components/TopBanner/TopBanner';
import Footers from '../../components/Footer/Footer';
import Appointent from '../../components/Appointments/Appointment';

class Appointment extends Component{
    componentDidMount() {
        window.scroll(0,0)
    }
    
    render(){     
        return(
            <Fragment>
            <TopNavigation/>
            <TopBanner/>
            <Container>
                <div>
                    <Container >
                        <Row xs="1" style={{fontFamily: "Arial", fontSize: 20 }}> 
                            <Col>
                            <br />
                            {/* <br /><br /><br /> */}
                            {/* <p style={{ fontSize: 30, fontWeight:"Bold", textAlign:"center" }}> This online appointment page is under construction </p>  */}
                            <p style={{ fontSize: 30, fontWeight:"Bold", textAlign:"center" }}> Online Appointment </p> 
                             <Appointent/>
                            <br /><br /><br /><br />
                        
                            </Col>
                        </Row>
                        
                    </Container>
                </div>
            </Container>
            <Footers/>
  
        </Fragment>

       
        );
        }
      }   
export default Appointment;