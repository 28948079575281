import React, { Component, Fragment } from 'react';

import  TopBanner from './TopBanner';
import ClinicalActivities from '../ClinicalActivities/ClinicalActivities';
import TopNavigation from '../TopNavigation/TopNavigation';
//import Circular from '../../pages/Academics/Admission';
import Circular from '../Academics/Academics';

import Doctors from '../Doctors/DoctorInformation';
import Footers from '../Footer/Footer';
// import {Router,Switch, Route} from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


// import ClinicalActivitiesMedia from './components/ClinicalActivities/ClinicalActivitiesMedia';
// import DoctorsDynamic from './components/Doctors/DoctorInformationDynamic';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



     
class Home extends Component{
    componentDidMount() {
        window.scroll(0,0)
    }
    
    render(){
        return(
            <Fragment>
                <TopNavigation/>
                <TopBanner/>
                <Doctors/>
                <ClinicalActivities/>
                <Circular/>
                <Footers/>
            </Fragment>
        );
    }
}

export default  Home;