import React, { Component, Fragment } from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {Router,Switch, Link} from 'react-router-dom';
import logo from '../../logo.svg';

import Circular from '../../pages/Academics/Admission';

import '../../assets/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class TopNavigation extends Component{
    constructor(){
        super();
        this.state={
            navBarTitle: "navTitle",
            navBarBg: "",
            navVariant: "dark navMenu",
            
        }
    }

    onScroll=()=>{
        if (window.scrollY>100){
             this.setState({navBarTitle:'navTitleScroll'})
             this.setState({navBarBg:'light'})   
             this.setState({navVariant:'navTitleScroll'})   
        }
        else if (window.scrollY<100){
            this.setState({navBarTitle:'navTitle'})   
            this.setState({navBarBg:''}) 
            this.setState({navVariant:'dark navMenu'})  
        }
    }

    componentDidMount(){
        window.addEventListener('scroll',this.onScroll)
    }


    render(){
        return(

            <Fragment>
                
                    <Navbar fixed="top" collapseOnSelect expand="lg" bg={this.state.navBarBg} variant={this.state.navVariant}>
                    <div>
                    <img src={logo} alt="logo" width="60" height="40" />
                    </div>
                        <Navbar.Brand className={this.state.navBarTitle} href="/">Chevron Eye Hospital</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                      
                                {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                </NavDropdown>  */}
                            </Nav>
                            <Nav>
                            {/* <span> <a href="/about-us">ABOUT US</a> </span>
                            <span> <a href="/clinical-activities">CLINICAL ACTIVITIES</a></span>
                            <span> <a href="/outreach-programme">OUTREACH PROGRAM</a></span>
                            <span> <a href="/academics">ACADEMICS</a></span>
                            <span> <a href="/contact-us">CONTACT US</a></span> */}
                                <Link to="/about-us">ABOUT US   |</Link>
                                <Link to="/clinical-activities">|   CLINICAL ACTIVITIES   |</Link>
                                <Link to="/outreach-programme">|   OUTREACH PROGRAM   |</Link>
                                <Link to="/our-doctors">|   OUR DOCTORS   |</Link>
                                <Link to="/academics">|   ACADEMICS   |</Link>
                                <Link to="/contact-us">|   CONTACT US   |</Link>
                                <Link to="/appointment">|   APPOINTMENT</Link>
                                {/* <Nav.Link href="/appointment">APPOINTMENT</Nav.Link> */}
                            </Nav>
                        </Navbar.Collapse>

                         
                    </Navbar>
                
            </Fragment>
        );
    }
}

export default  TopNavigation;