import React, { Component, Fragment } from 'react';
import {Container,Row,Col, CardDeck,Card,CardImg, Media} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/custom.css';
import Button from 'react-bootstrap/Button';


import circular from '../../assets/files/Program Circular.jpeg';
import circular2 from '../../assets/files/Clinical Optometry.jpg';
import vacancy from '../../assets/files/Vacancy.jpg';

import AcademicsPage from '../../pages/Academics/Admission';

 

const h="330";
const w="620"

class Academics extends Component{
    componentDidMount() {
        window.scroll(0,0)
    }
    
    render(){     
        return(
            <Fragment>
                <Container>
                    <div>
                        <Container >

                        <div className="clinicalActivitiesTitle text-center">
                            CIRCULAR 
                        </div>
                            {/* <Row xs="1" style={{fontFamily: "Arial", fontSize: 20 }}> 
                                <Col>
                                <p style={{ fontSize: 30, fontWeight:"Bold" }}> Academics </p>
                                </Col>
                            </Row> */}
                            {/* <Row xs="1" >
                                <Col>
                                    <p style={{ fontSize: 20 }}> <h3>This page is under Construction</h3> </p>                                
                                </Col>
                            </Row> */}
                            <Row class=" lg mb-6"> 
                                {/* <div class="mb-4"> */}
                                    <CardDeck>
                                    <Card>
                                        <Card.Img variant="top" src={circular} height={h} width={w}/>
                                        <Card.Body>
                                        <Card.Title className="cardTitle">Subspecialty Fellowship and Training Program</Card.Title>
                                        {/* <Card.Text className="cardTextAlignment"> </Card.Text> */}

                                        </Card.Body>
                                        <Card.Footer>
                                        <div class="mb-4">
                                            <Link to='/academics'> Details </Link>
                                            </div> 
                                        </Card.Footer>
                                    </Card>
                                    <Card>
                                        <Card.Img variant="top" src={circular2} height={h} width={w}/>
                                        <Card.Body>
                                        <Card.Title className="cardTitle">Internship in Clinical Optometry</Card.Title>
                                    
                                        </Card.Body>
                                        <Card.Footer>
                                            <div class="mb-2">
                                                <Link to='/academics'> Details </Link>

                                            </div>   
                                        </Card.Footer>
                                    </Card>

                                    <Card>
                                        <Card.Img variant="top" src={vacancy} height={h} width={w}/>
                                        <Card.Body>
                                        <Card.Title className="cardTitle">Job Vacancy</Card.Title>
                                    
                                        </Card.Body>
                                        <Card.Footer>
                                            <div class="mb-2">
                                                <Link to='/career'> Details </Link>
                                            </div>   
                                        </Card.Footer>
                                    </Card>

                                    
                                    </CardDeck>
                                {/* </div> */}
                            </Row>
                        </Container>
                    </div> 
                </Container>
            </Fragment>
        );
      }
}   
export default Academics;