import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Form, Row } from "react-bootstrap";




class Contact extends Component {
    render() {
        return (
            <Fragment>
            
                <Container className="TopSection">
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white mt-4" md={12} lg={12} sm={12} xs={12}>
                            <Row className="text-center ">
                                {/* <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form className="contactUs">
                                        <h4 className="section-title">Doctor Appointment</h4>
                                        <h6 className="section-sub-title">Your appointment shall be confirmed by Chevron Eye Hospital once sent your request</h6>
                                        <input className="form-control m-4" type="text" placeholder="Your Name"/>
                                        <input className="form-control m-4" type="text" placeholder="Mobile Number"/>
                                        <input className="form-control m-4" type="dropdown" placeholder="Doctor Name"/>
                                        <input className="form-control m-4" type="text" placeholder="Appointment Date"/>
                                        
                                        <Button className="btn btn-block m-4 site-btn">SEND</Button>
                                    </Form>
                                </Col> */}
                                <Col>
                                <Form >
                                <h4 className="section-title">Patient's Information</h4>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Enter your name*" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicMobile">
                                        <Form.Control type="text" placeholder="Enter your mobile number*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicDoctor">
                                        <Form.Control type="text" placeholder="Select Doctor Name*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicShift">
                                        <Form.Control type="text" placeholder="Select Shift*" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicAppDate">
                                        <Form.Control type="text" placeholder="Select Appointment Date*" />
                                    </Form.Group>

                                   

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} placeholder="Notes"/>
                                    </Form.Group>
                                    
                                    <Button variant="primary" type="submit">Book Appointment</Button>
                                    </Form>

                                   
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>

                    
                </Container>
             
            </Fragment>
        );
    }
}

export default Contact;