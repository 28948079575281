import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Router,Switch, Link} from 'react-router-dom';

import '../../assets/css/footer.css';

class Footer extends Component{
  render(){
    return(
      <Fragment>
        <div class="p-3 mb-4 bg-dark text-white"> 
          <Container style={{bgcolor: "Red"}}>
            <Row  > 
              <Col lg={4} md={4} sm={6} xs={12} >
              <p className="footer-head pd=2 bgColor">Our Address</p>
              <a style={{fontWeight: "bold"}}> Chevron Eye Hospital and Research Center </a>
              Chevron Clinical Lab. (Pte.) Ltd.(Level 7 and 8) <br/>
              12/12, O.R. Nizam Road, Panchlaish, Chattogram.<br/>
              Contact: 01786554976, 01858056644, 0312556877 
              
              </Col>
              <Col lg={4} md={4} sm={6} xs={12}>
              <p className="footer-head">Important Links</p>
              
              <Link to="/" className="footerNavigation">Home </Link> <br />
              <Link to="/about-us" className="footerNavigation">About us </Link> <br />
              <Link to="/our-doctors" className="footerNavigation">Doctors' Information</Link> <br />
              <Link to="/clinical-activities" className="footerNavigation">Clinical Information</Link> <br />
              <Link to="/career" className="footerNavigation">Career</Link> <br />
              

              </Col>
              
              <Col lg={4} md={4} sm={6} xs={12}>
              <p className="footer-head">Our Location</p>
              
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29519.830870105565!2d91.79421013016952!3d22.35442710889577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1621909570200!5m2!1sen!2sbd" width="350" height="200"> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.7421950963208!2d91.82790261399299!3d22.363361246256297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd88246759eef%3A0x95c0704edadb09f!2sChevron%20Eye%20Hospital!5e0!3m2!1sen!2sbd!4v1623149122086!5m2!1sen!2sbd" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.7421950963208!2d91.82790261399299!3d22.363361246256297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd88246759eef%3A0x95c0704edadb09f!2sChevron%20Eye%20Hospital!5e0!3m2!1sen!2sbd!4v1623149122086!5m2!1sen!2sbd" width="380" height="220"  style={{border:0,  loading:"lazy" }}></iframe>
                
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
} 

export default Footer;